<template>
  <loading
    v-model:active="is_loading"
    :can-cancel="false"
    :is-full-page="true"
  ></loading>
  <article class="message is-warning is-small" v-if="error_message">
    <div class="message-body">
      {{ error_message }}
    </div>
  </article>
  <div class="content" v-if="is_allow_action">
    <div class="deliverybox-info">
      <b>
        {{ deliverybox.store_address }}
      </b>
      <br />
      <b>
        {{ deliverybox.store_name }}&nbsp;&nbsp;{{
          deliverybox.store_locker_name
        }}
      </b>
    </div>
    以下の暗証番号を正しくセットしましたか？
    <div class="box-key">
      {{ deliverybox.store_locker_key }}
    </div>
    <PaymentActionFlow
      payment_status="done"
      select_status="done"
      post_status="done"
      complete_status="active"
    />
    <div class="btn-wrapper">
      <button class="button is-rounded btn-cancel" @click="toReceptionCancel">
        キャンセル
      </button>
      <button class="button is-rounded btn-submit" @click="createBoxReception">
        はい
      </button>
    </div>
    発送をとりやめる場合は<b>キャンセル</b>を選択してください。
  </div>
</template>

<script>
import BoxReceptionRepository from "../repositories/BoxReceptionRepository"
import DeliveryboxReceptionRepository from "../repositories/DeliveryboxReceptionRepository"
import MailService from "../services/MailService"
import GMOPaymentService from "../services/GMOPaymentService"
import { Auth } from "aws-amplify"
import Loading from "vue-loading-overlay"
import PaymentActionFlow from "../components/elements/PaymentActionFlow.vue"
export default {
  name: "PaymentStoreKeySetConfirm",
  components: {
    Loading,
    PaymentActionFlow,
  },
  data() {
    return {
      error_message: "",
      is_allow_action: false,
      is_loading: false,
      transaction: {
        transaction_token: "",
        sr_code: "",
        service_trc_no: "",
        optional_service_no: "",
        is_payment: "",
        order_no: "",
      },
    }
  },
  async created() {
    if (!this.$store.state.deliverybox.store_locker_key) {
      this.error_message =
        "必要な情報が存在しないため操作ができません。最初から操作を行ってください"
      return
    }
    DeliveryboxReceptionRepository.findByToken(this.$route.query.token)
      .then((transaction) => {
        this.transaction = transaction
        this.is_allow_action = true
      })
      .catch(() => {
        this.error_message =
          "事前受付が期限切れとなっています。最初から操作を行ってください。\nまたは、既に発送受付処理が完了している商品の可能性があります。ご確認ください。"
      })
  },
  computed: {
    deliverybox() {
      return this.$store.state.deliverybox
    },
  },
  methods: {
    async createBoxReception() {
      this.is_loading = true
      const box_reception_values = {
        store_no: this.deliverybox.store_no,
        service_code: this.transaction.sr_code,
        service_trc_no: this.transaction.service_trc_no,
        optional_service_no: this.transaction.optional_service_no,
        optional_store_locker_code: this.deliverybox.code,
        optional_store_locker_key: this.deliverybox.store_locker_key,
      }
      try {
        // スマリWEBの受付レコード発行
        const box_reception = await BoxReceptionRepository.create(
          box_reception_values
        )
        this.$store.commit("setBoxReception", {
          box_no: box_reception.box_no,
        })
        if (this.transaction.is_payment) {
          await GMOPaymentService.execute(this.transaction.order_no)
          await BoxReceptionRepository.createPayment({
            transaction_token: this.transaction.transaction_token,
            return_box_no: box_reception.box_no,
          })
        }
        // メール送信
        await MailService.sendBoxReceptionComplete(
          box_reception.box_no,
          this.deliverybox.store_locker_key
        )
        // トランザクション更新
        const transaction_update_values = {
          reception_status: "posting_completed",
          deliverybox_code: this.deliverybox.code,
          deliverybox_key: this.deliverybox.store_locker_key,
          return_box_no: box_reception.box_no,
          service_name: box_reception.service_name,
        }
        await DeliveryboxReceptionRepository.update(
          this.$route.query.token,
          transaction_update_values
        )
        this.$router.replace({ path: "/payment/store/complete" })
      } catch (error) {
        if (error.response) {
          if (error.response.status === 406) {
            this.error_message =
              "選択された宅配ボックスはスマリサービスに対応していないか、すでに荷物が投函されています。別の宅配ボックスよりご発送ください。"
            return
          }
        }
        this.error_message =
          "操作が正常に完了しませんでした。しばらく時間をおいて、最初から操作を行ってください。"
      } finally {
        this.is_loading = false
      }
    },
    toReceptionCancel() {
      this.$store.commit("clearDeliverybox")
      this.$router.push({
        path: `/reception/gmo-payment/${this.transaction.order_no}/cancel`,
      })
    },
  },
}
</script>

<style scoped>
.deliverybox-info {
  text-align: center;
  padding-bottom: 5px;
}
.box-key {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}
.btn-wrapper {
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;
  padding-bottom: 50px;
}
</style>
