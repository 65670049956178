import { Auth, API } from "aws-amplify"

export default {
  create: async (values) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
      body: {
        store_no: values.store_no,
        service_code: values.service_code,
        service_trc_no: values.service_trc_no,
        optional_service_no: values.optional_service_no,
        optional_store_locker_code: values.optional_store_locker_code,
        optional_store_locker_key: values.optional_store_locker_key,
      },
    }
    const result = await API.post("smariDeliveryboxAPI", `/box-reception`, init)
    return result
  },
  createPayment: async (values) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
      body: {
        transaction_token: values.transaction_token,
        return_box_no: values.return_box_no,
      },
    }
    const result = await API.post("smariDeliveryboxAPI", `/box-reception/payment`, init)
    return result
  }
}
